import React from "react";
import Layout from "../components/layout";
import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";
import StandardHeaderOneColumn from "../assets/elements/StandardHeaderOneColumn";
import Cta from "../partials/Cta";
import SEO from "../components/seo";

import DicomMipDemo from "../../static/media/dicom_mip_demo.webm";
import Dicom3DDemo from "../../static/media/radiology_3d_demo_cropped.webm";
import LogoCloud from "../assets/elements/LogoCloud";
import { StaticImage } from "gatsby-plugin-image";
import ThreeColumnFeatureSection from "../assets/elements/ThreeColumnFeatureSection";
import TwoFeatureOverview from "../assets/elements/TwoFeatureOverview";
import { Script } from "gatsby";
import CaseStudyMSK from "../partials/CaseStudyMSK";
import { EncordGradientText } from "../utilities/DesignUtilities";
import CustomLink from "../components/CustomLink";
import VideoComponent from "../components/VideoComponent";

export default function Radiology({ location }) {
  return (
    <Layout location={location}>
      <LeftColours />
      <RightColours />

      <StandardHeaderOneColumn
        title={
          <>
            Accelerate The Development of Computer Vision Models for{" "}
            <EncordGradientText text={"Radiology Annotation"} />
          </>
        }
        location={location}
        pageName="Radiology Page"
        description={
          "Get your radiology AI models into production faster with collaborative medical-grade DICOM and NIfTI annotation tooling from Encord. Build custom label protocols and generate automated worklists with the click of a button."
        }
        heroGraphic={
          <>
            <VideoComponent loop autoPlay muted className="rounded-lg">
              <source
                src={DicomMipDemo}
                type="video/webm"
                width="768"
                height="432"
              />
            </VideoComponent>
          </>
        }
      />
      <LogoCloud
        title={
          "Trusted by trailblazing computer vision teams at leading healthcare institutions"
        }
        pb={10}
        pbMd={10}
        numImages={5}
        imageOne={
          <img
            src={
              "https://images.prismic.io/encord/1716e248-7485-43e9-9454-bb17670cd7d4_stanford-medicine-logo-vector.png?auto=compress,format"
            }
            alt={"Stanford Medicine Logo"}
            className={"h-24 w-32"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageTwo={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/8ed83228-e834-494f-84d4-06f0b698c5d6_logo_uhn.svg"
            }
            alt={"UHN logo"}
            className={"h-24 w-24"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageThree={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/ab584df2-0365-496d-a450-9df08c58fade_Memorial+Sloan+Kettering+Cancer+Center-1.svg"
            }
            alt={"MSK logo"}
            className={"h-20 w-44"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageFour={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/aa834409-1594-49f1-872e-d6b0ec856626_NHS_gstt.svg"
            }
            alt={"St Thomas logo"}
            className={"h-24 w-36 lg:w-40"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageFive={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/f3a7d750-8fcf-4a89-9212-59a427503a98_klc.svg"
            }
            alt={"KCL logo"}
            className={"h-24 w-24"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
      />

      {/* Three column feature section */}
      <ThreeColumnFeatureSection
        title={"How it works"}
        header={
          "Encord Helps You Get Your Radiology Machine Learning Models Into Production 10x Faster"
        }
        description={
          <>
            <p>
              Encord is the only medical imagery annotation tool and computer
              vision data platform designed for all radiology modalities,
              including X-ray, mammography, CT, PET and MRI.
            </p>
            <br />
            <p>
              From the annotation interface to labeling protocols and worklist
              support, Encord can transform how you train and build your
              computer vision models for radiology.
            </p>
          </>
        }
        features={[]}
      />

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeHeader={false}
        title={""}
        header={""}
        description={""}
        imageOne={
          <VideoComponent loop autoPlay muted className="rounded-lg">
            <source
              src={Dicom3DDemo}
              type="video/webm"
              width="768"
              height="432"
            />
          </VideoComponent>
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../../static/media/radiology_ontologies.webp"}
            width={450}
            alt="Label protocols for radiology"
            className="rounded-lg"
          />
        }
        imageThree={
          <StaticImage
            loading="lazy"
            src={"../../static/media/radiology_brain.webp"}
            width={450}
            alt="Segmentation of brain in CT scan"
            className="rounded-lg"
          />
        }
        headerOne={"Medical Grade 3D Annotation Software"}
        headerTwo={"The Industry’s Most Powerful Labeling Protocols"}
        headerThree={"A Single Scalable Platform"}
        descriptionOne={
          "Give your medical imaging annotators a tool that is designed for them. Encord offers native DICOM and NIfTI image rendering with a PACS-style interface, including fully customisable hotkeys, configurable window level presets, multiplanar reconstruction (MPR) and maximum intensity projection (MIP)."
        }
        descriptionTwo={
          <>
            <p>
              Encord supports complex labeling protocols within its
              medical-grade annotation tool.
            </p>
            <br />
            <p>
              You can create rich labeling protocols covering as many feature
              types as you need to deliver the right training data to your
              machine learning team.
            </p>
          </>
        }
        descriptionThree={
          <>
            <p>
              Give your annotators a single platform for all their annotation
              types, including bounding box, polygon, polyline, keypoint,
              segmentation and classification.
            </p>
            <br />
            <p>
              And with a single platform, your developers are saved from
              maintaining multiple custom or open-source annotation tools.
            </p>
          </>
        }
      />

      <section className="pt-12 pb-12 border-t border-gray-200 bg-gradient-to-b from-gray-100 to-white">
        <div className="max-w-6xl px-4 mx-auto sm:px-6">
          <CaseStudyMSK includeCTALink />
        </div>
      </section>

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeTopBorder
        includeTopPadding
        includeHeader={false}
        title={""}
        header={""}
        description={""}
        imageOne={
          <StaticImage
            loading="lazy"
            src={"../../static/media/radiology_workflow.webp"}
            width={600}
            alt="Expert review quality control for radiology"
          />
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../../static/media/radiology_accuracy.webp"}
            width={550}
            alt="Encord improves the quality of your radiology training data"
          />
        }
        imageThree={
          <StaticImage
            loading="lazy"
            src={"../../static/media/radiology_approval.webp"}
            width={250}
            alt="Encord supports FDA clearance and CE marking processes"
          />
        }
        headerOne={"Streamline Your Clinical Data Operations"}
        headerTwo={"Deliver Better Training Data"}
        headerThree={"Supporting Your Regulatory Journey"}
        descriptionOne={
          <>
            <p>
              Make your annotator management processes more efficient with
              role-based access control, worklist support and configurable
              expert review pipelines.
            </p>
            <br />
            <p>
              It means you can ensure your most skilled radiologist annotators’
              time isn’t being wasted on the wrong tasks.
            </p>
          </>
        }
        descriptionTwo={
          <>
            <p>
              Encord helps clinical operations teams identify the errors, biases
              and imbalances in the datasets they’ve created (even down to
              individual annotator performance).
            </p>
            <br />
            <p>
              This allows clinical operations leaders to provide better datasets
              to their machine learning teams and help reduce model time to
              production.
            </p>
          </>
        }
        descriptionThree={
          <>
            <p>
              Encord has been designed to make it easier for you to meet your
              regulatory requirements, including FDA submissions and CE
              approval.
            </p>
            <br />
            <p>
              As well as being{" "}
              <CustomLink
                className="font-medium text-gray-600 hover:text-gray-900"
                to={"/security/"}
              >
                HIPAA and SOC2
              </CustomLink>{" "}
              compliant, Encord’s data pipelines, fully auditable labels and
              quality control and assurance features make regulatory compliance
              much less of a headache.
            </p>
          </>
        }
      />

      <Cta
        location={location}
        pageName={"Radiology Page"}
        ctaText={"AI-powered image annotation for CT, PET, X-ray and MRI"}
      />
    </Layout>
  );
}

export const Head = () => (
  <>
    <Script id="g2crowd-tracking-script" defer>
      {`(function (c, p, d, u, id, i) {
  id = ''; // Optional Custom ID for user in your system
  u = 'https://tracking.g2crowd.com/attribution_tracking/conversions/' + c + '.js?p=' + encodeURI(p) + '&e=' + id;
  i = document.createElement('script');
  i.type = 'application/javascript';
  i.async = true;
  i.src = u;
  d.getElementsByTagName('head')[0].appendChild(i);
}("1008306", document.location.href, document));`}
    </Script>
    <SEO
      title="Active Learning Platform for Radiology Computer Vision | Encord"
      description="We're helping leading medical institutions and companies accelerate AI model development with collaborative 3D annotation tools & active learning pipelines."
    >
      <meta name="robots" content="noindex, nofollow" />
    </SEO>
  </>
);
